import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core";
import {
    SortableContext,
    arrayMove,
    horizontalListSortingStrategy,
    sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { forwardRef, useEffect, useState } from "react";

import { SortableItem } from "../sortableItem";
import { defaultData } from "../../correct";
import styles from "./styles.module.scss";

const correctOrder = [
    defaultData.quersumme2.correct,
    defaultData.hhDomAmount.correct,
    defaultData.east.correct,
    defaultData.specialGuest.correct,
    defaultData.lng.correct,
];

const Geschenk = forwardRef(({ next, data }, ref) => {
    const givenAnswers = [
        data.quersumme2.given,
        data.hhDomAmount.given,
        data.east.given,
        data.specialGuest.given,
        data.lng.given,
    ].sort((a, b) => 0.5 - Math.random());

    const [showNextButton, setShowNextButton] = useState(true);
    const handleNextClick = () => {
        next();
        setShowNextButton(false);
    };

    const [isInCorrectOrder, setIsInCorrectOrder] = useState(false);
    const [items, setItems] = useState(givenAnswers);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    useEffect(() => {
        setIsInCorrectOrder(JSON.stringify(correctOrder) === JSON.stringify(items));
    }, [items]);

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            setItems((items) => {
                const oldIndex = items.indexOf(active.id);
                const newIndex = items.indexOf(over.id);

                return arrayMove(items, oldIndex, newIndex);
            });
        }
    };

    const skip = () => {
        setItems(correctOrder);
    };

    return (
        <>
            <section ref={ref}>
                <h2>Jetzt aber wirklich letzte Aufgabe</h2>
                <p>
                    Du hast es geschafft! Ich hoffe, dass dein Tag genauso spannend war, wie du es dir gewünscht hast -
                    oder vielleicht sogar besser!
                </p>
                <p>
                    Deine letzte Aufgabe besteht darin <b>fünf Zahlen</b> in die richtige <i>Reihenfolge</i> zu bringen.
                    Sobald du das geschafft hast öffnet sich dein Geschenk! Ein kleiner Tipp: Es handelt sich um eine
                    Postleitzahl.
                </p>
            </section>

            {items.length > 0 && (
                <>
                    <section style={{ marginBottom: 0 }}>
                        <h3>Aufgabe</h3>
                        <p>Bringe die Zahlen in die richtige Reihenfolge!</p>
                        <p>
                            Falls sich die Felder nicht grün färben hast du vielleicht irgendwo eine falsche Antwort
                            gegeben oder die Reihenfolge stimmt nicht.
                        </p>
                    </section>

                    <section className="green">
                        <div
                            className={[styles.sortableArea, isInCorrectOrder ? styles.isInCorrectOrder : ""].join(" ")}
                        >
                            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                                <SortableContext items={items} strategy={horizontalListSortingStrategy}>
                                    {items.map((id) => (
                                        <SortableItem key={id} id={id} />
                                    ))}
                                </SortableContext>
                            </DndContext>
                        </div>
                    </section>

                    {showNextButton && (
                        <>
                            <button className="simple" onClick={skip}>
                                Überspringen
                            </button>

                            <section>
                                <div className={styles.buttons}>
                                    <button onClick={handleNextClick} disabled={!isInCorrectOrder}>
                                        Sesam öffne dich!
                                    </button>
                                </div>
                            </section>
                        </>
                    )}
                </>
            )}
        </>
    );
});

export default Geschenk;
