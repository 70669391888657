import styles from "./styles.module.scss";

const Checkbox = ({ label, ...rest }) => (
    <label className={styles.container}>
        <input type="checkbox" {...rest} />
        <span className={styles.icon} />
        {label && label}
    </label>
);

export default Checkbox;
