import cover from "./cover.png";
import giraffe from "./giraffe.png";
import nala from "./nala.png";
import styles from "./styles.module.scss";
import { useState } from "react";

const Start = ({ next }) => {
    const [showNextButton, setShowNextButton] = useState(true);
    const handleNextClick = () => {
        next();
        setShowNextButton(false);
    };
    return (
        <>
            <section>
                <img src={cover} alt="" width="100%" />
                <h1>happy birthday</h1>

                <h2>Guten Morgen Hasipups,</h2>
                <p>
                    Du hast immer wieder gesagt, dass du an deinem Geburtstag <i>nicht zu Hause</i> sitzen möchtest -
                    also machen wir das nicht ...
                </p>
                <p>
                    Freu dich auf einen Tag voller <b>Rätsel</b> lösen und <b>Schnitzel jagen</b>! Und da ich ja weiß,
                    dass du es nicht magst, wenn du nicht weißt, was passiert gibt's hier für dich eine kleine
                    Tagesagenda, an der du dich lang hangeln kannst :-)
                </p>
                <img src={giraffe} alt="" width="100%" />
                <p>
                    Und wie du schon bemerkt hast ist Nala nicht hier, wir können uns also ganz auf uns konzentrieren
                    und Orte besuchen, wo du schon warst - oder auch nicht.
                </p>
                <img src={nala} alt="" width="100%" />
            </section>

            <section className="yellow">
                <h3>Timeline</h3>
                <ul className="list">
                    <li>lecker Frühstück</li>
                    <li>Zwei drei vier Sachen zusammen packen, ich trage das für dich!</li>
                    <li>Bahn fahren</li>
                    <li>rum laufen und was erleben</li>
                    <li>wo rein gehen und was erleben</li>
                    <li>was essen gehen und was erleben</li>
                    <li>endlich dein Geschenk bekommen</li>
                </ul>
            </section>

            {showNextButton && (
                <section>
                    <div className={styles.buttons}>
                        <button className="secondary" onClick={handleNextClick}>
                            Und los gehts
                        </button>
                    </div>
                </section>
            )}
        </>
    );
};

export default Start;
