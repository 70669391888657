import { forwardRef, useState } from "react";

import bonus from "./bonus.png";
import dom from "./dom.png";
import maps from "./maps.png";
import styles from "./styles.module.scss";

const HHDom = forwardRef(({ next, data, updateData }, ref) => {
    const [showNextButton, setShowNextButton] = useState(true);
    const handleNextClick = () => {
        next();
        setShowNextButton(false);
    };

    const onChangeInput = (event) => {
        const { name, value } = event.target;
        updateData({ name, value });
    };

    const inputsCorrect =
        data.hhDomAmount.given === data.hhDomAmount.correct && data.money.given === data.money.correct;

    return (
        <>
            <img src={dom} alt="" width="100%" ref={ref} />
            <section>
                <h2>Uuuund los geht die wilde Fahrt - Hamburger DOM</h2>
                <p>
                    Hier wolltest du schon lange mal wieder hin - jetzt sind wir hier! Aber auch nicht all zu lang, denn
                    wir müssen uns gegen <b>16:00 Uhr</b> auch schon wieder auf den Weg machen. Aber trotzdem gibt es
                    hier auch noch die ein oder andere Aufgabe zu erfüllen.
                </p>

                <h3>Aufgabe</h3>
                <div>
                    <p>
                        Wie viel mal im Jahr findet der <i>Hamburger DOM</i> statt?
                    </p>
                    <input
                        type="number"
                        name="hhDomAmount"
                        value={data.hhDomAmount.given || ""}
                        placeholder="Anzahl"
                        onChange={onChangeInput}
                        className={parseFloat(data.hhDomAmount.given, 10) === data.hhDomAmount.correct ? "special" : ""}
                    />
                    <p>
                        Jetzt wird's ein wenig fummelig. Wie viele <b>Euro</b> hat Stefan in seiner hinteren{" "}
                        <i>Hosentasche</i>?
                    </p>
                    <input
                        type="number"
                        name="money"
                        value={data.money.given || ""}
                        placeholder="€"
                        onChange={onChangeInput}
                    />
                </div>
            </section>

            {inputsCorrect && (
                <>
                    <section>
                        <h3>Bonusrunde</h3>
                        <img src={bonus} alt="" width="100%" ref={ref} />
                        <p>
                            Bestimmt ist dir aufgefallen, dass uns jemand immer und immer wieder begegnet ist, oder? Das
                            war nicht ohne Grund! Wie oft sind wir uns über den Weg gelaufen?
                        </p>
                        <input
                            type="number"
                            name="specialGuest"
                            value={data.specialGuest.given || ""}
                            placeholder="Anzahl"
                            onChange={onChangeInput}
                            className={
                                parseFloat(data.specialGuest.given, 10) === data.specialGuest.correct ? "special" : ""
                            }
                        />
                    </section>

                    <section>
                        <h3>Unser nächstes Ziel</h3>
                    </section>
                    <img src={maps} alt="" width="100%" />
                    <section className="blue">
                        <div className={styles.street}>
                            {data.meta.street}{" "}
                            <div>
                                {data.hhDomAmount.given}
                                {data.money.given}
                            </div>
                        </div>
                    </section>

                    {showNextButton && (
                        <section>
                            <div className={styles.buttons}>
                                <button
                                    className="secondary"
                                    onClick={handleNextClick}
                                    disabled={null === data.specialGuest.given}
                                >
                                    Let's go east
                                </button>
                            </div>
                        </section>
                    )}
                </>
            )}
        </>
    );
});

export default HHDom;
