import { forwardRef, useState } from "react";

import east1 from "./east1.jpg";
import east2 from "./east2.jpg";
import east3 from "./east3.jpg";
import styles from "./styles.module.scss";

const Hotel = forwardRef(({ next, data, updateData }, ref) => {
    const [showNextButton, setShowNextButton] = useState(true);
    const handleNextClick = () => {
        next();
        setShowNextButton(false);
    };

    const onChangeInput = (event) => {
        const { name, value } = event.target;
        updateData({ name, value });
    };

    return (
        <>
            <section ref={ref}>
                <h2>Willkommen im EAST Hotel</h2>
                <p>
                    Der Tag ist ja noch lange nicht zu Ende, aber nach Hause fahren dauert jetzt auch ganz schön lange.
                    Und kalt war's jetzt auch ganz schön, oder?
                </p>
                <p>
                    Was hälst du davon, wenn wir uns das heute Nacht hier gemütlich machen? Wenn du Lust hast können wir
                    auch noch eine Runde in die Sauna und uns mal so richtig aufwärmen!
                </p>
            </section>

            <img src={east1} alt="" width="100%" />
            <img src={east2} alt="" width="100%" />
            <img src={east3} alt="" width="100%" />

            <section>
                <p>
                    <b>ABER:</b> Wir haben immer noch etwas vor, laut googleMaps brauchen wir zu Fuß da ganze{" "}
                    <b>12 Minuten</b> hin. Aber ich hoffe, dass wir da nichts <i>Versalzenes</i> finden, sondern wir mit
                    bestem <i>Silberbesteck</i> empfangen werden. Na, hast du schon eine Ahnung, wo wir als nächstes
                    hingehen werden?
                </p>
            </section>
            <section className="yellow">
                <h2>
                    Wir müssen um <b>20:30 Uhr</b> da sein!
                </h2>
            </section>
            <section>
                <p>
                    Ach ja, Aufgaben müssen aber trotzdem noch gelöst werden! Du willst doch schließlich wissen, was
                    dein Geschenk ist, oder?
                </p>

                <h3>Aufgabe</h3>
                <div>
                    <p>
                        Du hast doch am Empfang einen <i>Zettel</i> bekommen, oder? Was stand drauf?
                    </p>
                    <input
                        type="number"
                        name="east"
                        value={data.east.given || ""}
                        placeholder="Zahl"
                        onChange={onChangeInput}
                        className={data.east.given === data.east.correct ? "special" : ""}
                    />
                </div>

                {showNextButton && (
                    <div className={styles.buttons}>
                        <button className="secondary" onClick={handleNextClick} disabled={null === data.east.given}>
                            Hast du Hunger?
                        </button>
                    </div>
                )}
            </section>
        </>
    );
});

export default Hotel;
