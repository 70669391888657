export const defaultData = {
    lat: {
        correct: 53,
        given: null,
    },
    lng: {
        correct: 9,
        given: null,
    },
    hhDomAmount: {
        correct: 3,
        given: null,
    },
    money: {
        correct: 1,
        given: null,
    },
    specialGuest: {
        correct: 6,
        given: null,
    },
    east: {
        correct: 7,
        given: null,
    },
    quersumme2: {
        correct: 2,
        given: null,
    },
    meta: {
        street: "Simon-von-Utrecht-Straße",
    },
};
