import { forwardRef, useEffect, useState } from "react";

import Checkbox from "../checkbox";
import image from "./todo.png";
import styles from "./styles.module.scss";

const TodoList1 = forwardRef(({ next, data, updateData }, ref) => {
    const [enabled, setEnabled] = useState(false);
    const [checked, setChecked] = useState({
        klamotten: false,
        schuhe: false,
        money: false,
        goldcoin: false,
    });

    const [showNextButton, setShowNextButton] = useState(true);
    const handleNextClick = () => {
        next();
        setShowNextButton(false);
    };

    useEffect(() => {
        let allChecked = true;
        Object.values(checked).forEach((item) => {
            if (!item) allChecked = false;
        });
        if (null === data.lat.given || null === data.lng.given) allChecked = false;
        setEnabled(allChecked);
    }, [data, checked]);

    const onChangeCheckbox = (target) => setChecked((prevState) => ({ ...prevState, [target.name]: target.checked }));
    const onChangeInput = (event) => {
        const { name, value } = event.target;
        updateData({ name, value });
    };

    const showTarget = () => {
        const url = `http://maps.apple.com/?ll=${data.lat.given}.5544081,${data.lng.given}.9692246&z=17`;
        if (window.confirm("Bist du dir wirklich sicher?")) window.open(url);
    };

    return (
        <>
            <section ref={ref}>
                <h2>Todo Liste</h2>
                <p>
                    Hier findest du nun Dinge, die du machen, einpacken und/oder anziehen musst (Es könnte kalt werden,
                    es sind <b>8°C</b> angesagt, vielleicht auch ein wenig Regen).
                </p>
            </section>

            <img src={image} alt="" width="100%" />

            <section className="purple">
                <ul className={styles.list}>
                    <li>
                        <Checkbox
                            label="1x Klamotten für den nächsten Tag"
                            name="klamotten"
                            id="klamotten"
                            onChange={(event) => onChangeCheckbox(event.target)}
                            value={checked.klamotten}
                        />
                    </li>
                    <li>
                        <Checkbox
                            label="Warme, aber bequeme Schuhe"
                            name="schuhe"
                            id="schuhe"
                            onChange={(event) => onChangeCheckbox(event.target)}
                            value={checked.schuhe}
                        />
                    </li>
                    <li>
                        <Checkbox
                            label="Dein Portemonnaie"
                            name="money"
                            id="money"
                            onChange={(event) => onChangeCheckbox(event.target)}
                            value={checked.money}
                        />
                    </li>
                    <li>
                        <Checkbox
                            label="Jörn's Goldmünze"
                            name="goldcoin"
                            id="goldcoin"
                            onChange={(event) => onChangeCheckbox(event.target)}
                            value={checked.goldcoin}
                        />
                    </li>
                </ul>
            </section>
            <section>
                <h3>Aufgabe</h3>
                <div>
                    Bilde die{" "}
                    <a
                        href="https://www.nachhilfe-team.net/lernen-leicht-gemacht/quersumme/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <i>Quersumme</i>
                    </a>{" "}
                    aus folgendem Ergebnis:
                    <code>4 * 28 - 3&#0178; + 5</code>
                    <input
                        type="number"
                        name="lng"
                        value={data.lng.given || ""}
                        placeholder="Dein Ergebnis"
                        onChange={onChangeInput}
                        className={data.lng.given === data.lng.correct ? "special" : ""}
                    />
                    <br />
                    <br />
                    <input
                        type="number"
                        name="lat"
                        value={data.lat.given || ""}
                        placeholder="Noch ne Zahl"
                        onChange={onChangeInput}
                    />
                </div>

                <p>
                    Wenn du alles erledigt hast und die Aufgaben richtig erfüllt sind kannst du auf diesen Button
                    klicken und du wirst erfahren, wohin es zu erst geht:
                </p>
                <div className={styles.buttons}>
                    <button onClick={showTarget} disabled={!enabled}>
                        Zeig mir den Weg
                    </button>
                    {showNextButton && (
                        <button className="secondary" onClick={handleNextClick} disabled={!enabled}>
                            Weiter geht's
                        </button>
                    )}
                </div>
            </section>
        </>
    );
});

export default TodoList1;
