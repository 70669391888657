import { CSS } from "@dnd-kit/utilities";
import styles from "./styles.module.scss";
import { useSortable } from "@dnd-kit/sortable";

export function SortableItem(props) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style} className={styles.item} {...attributes} {...listeners}>
            {props.id}
        </div>
    );
}
