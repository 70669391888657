import "./App.scss";

import { useEffect, useRef, useState } from "react";

import Geschenk from "./components/geschenk";
import HHDom from "./components/hhdom";
import Hotel from "./components/hotel";
import SaltAndSilver from "./components/saltAndSilver";
import Start from "./components/start";
import TodoList1 from "./components/todolist1";
import Villas from "./components/villas";
import { defaultData } from "./correct";

function App() {
    let refs;

    const [currentStep, setCurrentStep] = useState(parseFloat(localStorage.getItem("currentStep"), 10) || 0);
    const [data, setData] = useState(JSON.parse(localStorage.getItem("data")) || defaultData);

    useEffect(() => {
        const elem = refs[currentStep].current;
        if (elem) {
            const { top } = elem.getBoundingClientRect();
            const newPosition = top + window.scrollY;
            window.scrollTo({ top: newPosition, behavior: "smooth" });
        }
    }, [currentStep, refs]);

    useEffect(() => localStorage.setItem("currentStep", currentStep), [currentStep]);
    useEffect(() => localStorage.setItem("data", JSON.stringify(data)), [data]);

    const updateData = ({ name, value }) => {
        setData((prevState) => ({ ...prevState, [name]: { ...prevState[name], given: parseFloat(value, 10) } }));
    };

    refs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

    const next = () => setCurrentStep((prevState) => prevState + 1);

    const fillData = () => {
        let newData = {};
        Object.keys(defaultData).forEach((key) => {
            if (key !== "meta") {
                newData[key] = {
                    ...defaultData[key],
                    given: defaultData[key].correct,
                };
            }
        });

        setData((prevState) => ({
            ...prevState,
            ...newData,
        }));
    };
    const reset = () => {
        localStorage.clear();
        window.location.href = window.location.href;
    };

    return (
        <div className="App" ref={refs[0]}>
            <main>
                {currentStep >= 0 && <Start next={next} />}
                {currentStep >= 1 && <TodoList1 next={next} ref={refs[1]} data={data} updateData={updateData} />}
                {currentStep >= 2 && <HHDom next={next} ref={refs[2]} data={data} updateData={updateData} />}
                {currentStep >= 3 && <Hotel next={next} ref={refs[3]} data={data} updateData={updateData} />}
                {currentStep >= 4 && <SaltAndSilver next={next} ref={refs[4]} data={data} updateData={updateData} />}
                {currentStep >= 5 && <Geschenk next={next} data={data} ref={refs[5]} />}
                {currentStep >= 6 && <Villas ref={refs[6]} />}

                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 8 }}>
                    <button className="simple" onClick={reset}>
                        reset
                    </button>
                    <button className="simple" onClick={fillData}>
                        fill
                    </button>
                </div>
            </main>
        </div>
    );
}

export default App;
