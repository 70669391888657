import { forwardRef, useState } from "react";

import bar from "./bar.jpg";
import food from "./food.jpg";
import hamburg from "./hamburg.jpg";
import location from "./location.jpg";
import logoSaltAndSilver from "./saltAndSilver.png";
import styles from "./styles.module.scss";

const SaltAndSilver = forwardRef(({ next, data, updateData }, ref) => {
    const [showNextButton, setShowNextButton] = useState(true);
    const handleNextClick = () => {
        next();
        setShowNextButton(false);
    };

    const onChangeInput = (event) => {
        const { name, value } = event.target;
        updateData({ name, value });
    };

    return (
        <>
            <section ref={ref}>
                <h2>Yammi Yammi Yammi, I got love in my tummy!</h2>

                <img src={logoSaltAndSilver} alt="" width="100%" />
            </section>
            <section className="yellow">
                <p>
                    <i className="classic">
                        Soulfood, inspiriert aus der Levante Region. Wir verwenden nur Zutaten höchster Qualität,
                        darunter selbst angebautes Gemüse und Kräuter, direkt importierte Spezialitäten, Fisch aus
                        nachhaltiger Zucht und Fleisch aus Freilandhaltung.
                    </i>
                </p>
            </section>
            <section>
                <p>
                    Na, wie klingt das für dich? <i>Mehr?</i> Ok, dann hier ein paar Impressionen!
                </p>
            </section>

            <img src={hamburg} alt="" width="100%" />
            <img src={location} alt="" width="100%" />
            <img src={bar} alt="" width="100%" />
            <img src={food} alt="" width="100%" />

            <section>
                <h3>(Fast) letzte Aufgabe</h3>
                <div>
                    <p>
                        Ok das ist jetzt einfach! Gestern warst du ja noch ein Jahr jünger, wenn du daraus die{" "}
                        <i>Quersumme</i> nimmst - und daraus nochmal die <i>Quersumme</i> nimmst, dann kommt was raus?
                    </p>
                    <input
                        type="number"
                        name="quersumme2"
                        value={data.quersumme2.given || ""}
                        placeholder=""
                        onChange={onChangeInput}
                        className={data.quersumme2.given === data.quersumme2.correct ? "special" : ""}
                    />
                </div>

                {showNextButton && (
                    <div className={styles.buttons}>
                        <button onClick={handleNextClick} disabled={null === data.quersumme2.given}>
                            Und hier ist dein Geschenk!
                        </button>
                    </div>
                )}
            </section>
        </>
    );
});

export default SaltAndSilver;
