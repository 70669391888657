import footer from "./footer.png";
import { forwardRef } from "react";
import img1 from "./villas1.jpg";
import img2 from "./villas2.jpg";
import img3 from "./villas3.jpg";
import img4 from "./villas4.jpg";
import img5 from "./villas5.jpg";
import img6 from "./villas6.jpg";
import img7 from "./villas7.jpg";
//import styles from "./styles.module.scss";

const Villas = forwardRef((_, ref) => {
    return (
        <>
            <section ref={ref}>
                <h1>The Villas</h1>
                <p>
                    Wir beide (oder auch drei) fahren für ein paar Tage weg - und zwar nach <b>Fehmarn</b>. Dort gibt es
                    solche <i>wunderschönen</i> kleinen Villen, von denen wir uns eine aussuchen. Dort gibt es ganz viel
                    Natur, Ruhe, <i>Strand</i>, eine <b>Sauna</b> und bestimmt auch ganz viel <b>Wellness</b> für Herz
                    und Seele!
                </p>
                <p>
                    Lass uns mal schauen, wann wir da hin wollen und dann geht's los! Da dieses <i>Geschenk</i> jetzt am
                    Ende doch ein wenig eskaliert ist, ist das Ganze für deinen <b>Geburtstag</b> und <b>Weihnachten</b>{" "}
                    zusammen!
                </p>
            </section>

            <img src={img1} alt="" width="100%" />
            <img src={img2} alt="" width="100%" />
            <img src={img3} alt="" width="100%" />
            <img src={img4} alt="" width="100%" />
            <img src={img5} alt="" width="100%" />
            <img src={img6} alt="" width="100%" />
            <img src={img7} alt="" width="100%" />
            <img src={footer} alt="" width="100%" />
        </>
    );
});

export default Villas;
